import React from 'react';

export const SVGLinkedIn = () =>{
    return(
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="40px" height="40px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
        <g>
            <path className="icon" fill="#FFFFFF" d="M20.943,18.842v-0.033c-0.006,0.011-0.016,0.022-0.021,0.033H20.943z"/>
            <path className="icon" fill="#FFFFFF" d="M20,0C8.954,0,0,8.954,0,20s8.954,20,20,20s20-8.954,20-20S31.046,0,20,0z M15.708,27.516h-3.371V17.374
                h3.371V27.516z M14.022,15.99h-0.023c-1.131,0-1.863-0.78-1.863-1.753c0-0.996,0.755-1.753,1.908-1.753
                c1.154,0,1.863,0.757,1.885,1.753C15.929,15.21,15.198,15.99,14.022,15.99z M27.865,27.516h-3.371v-5.428
                c0-1.361-0.488-2.293-1.709-2.293c-0.932,0-1.484,0.627-1.729,1.234c-0.091,0.216-0.113,0.518-0.113,0.82v5.664h-3.372
                c0,0,0.045-9.189,0-10.142h3.372v1.437c0.449-0.69,1.25-1.675,3.039-1.675c2.22,0.002,3.883,1.451,3.883,4.566V27.516z"/>
        </g>
        </svg>
    );
}