import React, {useEffect, useRef, useState} from 'react';
import '../../styles/OnScreen.scss';

/* Animates elements when entering screen

*/

const OnScreen = ({children, options, anim}) =>{

    const ref = useRef(null);
    const [isIntersecting, setIntersecting] = useState(false);
    const [isBottom, setIsBottom] = useState(true);

    const callbackFunction = ([entry]) =>
    {
        setIntersecting(entry.isIntersecting);

        if(entry.boundingClientRect.top > 0 + entry.boundingClientRect.height)
            setIsBottom(true);
        else
            setIsBottom(false);
    }

    useEffect(()=>{
        const current = ref.current;
        const observer = new IntersectionObserver(callbackFunction, options);

        if(ref.current){
            observer.observe(ref.current);
        }
    
        return () => {
          observer.unobserve(current);
        };
    }, [ref, options]);

    return <section ref={ref} className={anim + (isIntersecting ? " visible" : "") + (isBottom ? " bottom" : " top") }>{children}</section>;
}

export default OnScreen;