// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slidefade-in {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in, transform 0.5s ease-out;
  will-change: opacity, visibility;
}

.slidefade-in.bottom {
  transform: translate(0, 25%);
}

.slidefade-in.top {
  transform: translate(0, -25%);
}

.slidefade-in.visible {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fade-in {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in;
  will-change: opacity, visibility;
}

.fade-in.visible {
  opacity: 1;
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/styles/OnScreen.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,kBAAA;EACA,uDAAA;EACA,gCAAA;AACJ;;AACA;EACI,4BAAA;AAEJ;;AAAA;EACI,6BAAA;AAGJ;;AADA;EACE,UAAA;EACA,mBAAA;EACA,eAAA;AAIF;;AAFA;EACI,UAAA;EACA,kBAAA;EACA,8BAAA;EACA,gCAAA;AAKJ;;AAHA;EACE,UAAA;EACA,mBAAA;AAMF","sourcesContent":[".slidefade-in{\r\n    opacity: 0;\r\n    visibility: hidden;\r\n    transition: opacity 1s ease-in, transform .5s ease-out;\r\n    will-change: opacity, visibility;\r\n}\r\n.slidefade-in.bottom{\r\n    transform: translate(0, 25%);\r\n}\r\n.slidefade-in.top{\r\n    transform: translate(0, -25%);\r\n}\r\n.slidefade-in.visible {\r\n  opacity: 1;\r\n  visibility: visible;\r\n  transform: none;\r\n}\r\n.fade-in{\r\n    opacity: 0;\r\n    visibility: hidden;\r\n    transition: opacity 1s ease-in;\r\n    will-change: opacity, visibility;\r\n}\r\n.fade-in.visible {\r\n  opacity: 1;\r\n  visibility: visible;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
