const butsbalData = {
    "title": "Butsbal",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unity3D",
    "description": "Butsbal is a fast paced multiplayer shooter game inspired by pong. Play the four different game modes with or against friends in the local multiplayer or play against strangers in the online multiplayer. The clean and minimalist design puts the focus on the gameplay.\n\n"+
            "Players will carry up to 5 bullets and will receive a new bullet every 3 seconds or kill. Bullets fired by the players will travel at a constant velocity and will bounce against the borders. Which makes it a hectic but fun shooter. You always have to watch your back!",
    "mywork": "I made the art with Adobe Illustrator, music with Fruity Loops and programmed the game in C#. I also did the publishing on Steam, Humble Bundle and Itch.io.",
    "videos": [{"src": "https://www.youtube.com/embed/oQHLbCCC2IE", "width": "560px", "height": "315px"}],
    "imagecolumns": 1,
    "images": [{"src": "/images/portfolio/Butsbal-Screenshot-CTF1.jpg"},
            {"src": "/images/portfolio/Butsbal-Screenshot-LMS.jpg"},
            {"src": "/images/portfolio/Butsbal-Screenshot-LMS1.jpg"},
            {"src": "/images/portfolio/Butsbal-Screenshot-Score.jpg"}],
    "links": [{"url": "https://store.steampowered.com/app/375330/Butsbal/", "name": "Steam"},
                {"url": "https://mrhankey91.itch.io/butsbal", "name": "Itchio"}],
};
const dungeonSweeperData = {
    "title": "DungeonSweeper",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unity3D",
    "description": "DungeonSweeper is a game inspired by Minesweeper. The player must find his way through dungeons. Avoiding and fighting enemies. Search for coins and the key to continue.",
    "mywork": "I made the graphics with Adobe Illustrator and programmed the game in c#.",
    "videos": [{"src": "https://www.youtube.com/embed/vCq-3id7nB4", "width": "300px", "height": "480px"}],
    "imagecolumns": 2,
    "images": [{"src": "/images/portfolio/DungeonSweeper_Menu.jpg"},
            {"src": "/images/portfolio/DungeonSweeper_Game1.jpg"},
            {"src": "/images/portfolio/DungeonSweeper_Game2.jpg"},
            {"src": "/images/portfolio/DungeonSweeper_Game3.jpg"}],
    "links": [],
};
const lightroomData = {
    "title": "Photo Editing",
    "subtitle": "Lightroom",
    "subsubtitle": "",
    "description": "Photo edits with Adobe Lightroom. The photos were taken while on my road trip through the Southwestern United States and a stop in New York City.\n\n"+
            "In order: New York City (New York), Grand Teton (Wyoming), Twin Falls (Idaho), Monument Valley (Arizona) and last two are Yosemite (California)",
    "mywork": "I made the pictures and edited them in Lightroom.",
    "videos": [],
    "imagecolumns": 1,
    "images": [{"src": "/images/portfolio/Lightroom_1.jpg"},
            {"src": "/images/portfolio/Lightroom_2.jpg"},
            {"src": "/images/portfolio/Lightroom_3.jpg"},
            {"src": "/images/portfolio/Lightroom_4.jpg"},
            {"src": "/images/portfolio/Lightroom_5.jpg"},
            {"src": "/images/portfolio/Lightroom_6.jpg"}],
    "links": [],
};
const moneyGardenData = {
    "title": "Money Garden",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unity3D",
    "description": "You have a tree in your garden a MONEYTREE! Strap your lawn vacuum on you back and collect all the money it drops. Try to keep your garden clean.",
    "mywork": "I made the 3D models in Autodesk Maya, textures in Photoshop and programmed the game using c#.",
    "videos": [{"src": "https://www.youtube.com/embed/HBz8i7TiNew?si=nEfC-q5IDP3tbo6Z", "width": "560px", "height": "315px"}],
    "imagecolumns": 1,
    "images": [{"src": "/images/portfolio/MoneyGarden_Game1.jpg"},
            {"src": "/images/portfolio/MoneyGarden_Game2.jpg"},
            {"src": "/images/portfolio/MoneyGarden_Game3.jpg"}],
    "links": [{"url": "https://store.steampowered.com/app/2586890/Money_Garden/", "name": "Steam"},
        {"url": "https://mrhankey91.itch.io/money-garden", "name": "Itchio"}],
};
const farmerijData = {
    "title": "Farmerij",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unity3D",
    "description": "Plant cute little plants, grow them by giving them water and sun. Collect your harvest and protect your plant from weed and crows.",
    "mywork": "I made the 2D art in Illustrator and programmed the game using c#.",
    "videos": [{"src": "https://www.youtube.com/embed/Z1NIXy5raXk?si=a5PYTCjVeAJHvu9Z", "width": "560px", "height": "315px"}],
    "imagecolumns": 1,
    "images": [{"src": "/images/portfolio/farmerij_screenshot.jpg"}],
    "links": [{"url": "https://mrhankey91.itch.io/farmerij", "name": "Itchio"}],
};
const bunnyTownData = {
    "title": "BunnyTown",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unity3D",
    "description": "Bunny Town is a game where you have to build a little town for your bunnies. Grow food and give them water. And build toys so your bunnies can play.",
    "mywork": "I made the 3D models in Autodesk Maya, textures in Photoshop and programmed the game using c#.",
    "videos": [],
    "imagecolumns": 1,
    "images": [{"src": "/images/portfolio/bunny-town-screenshot1.jpg"},
            {"src": "/images/portfolio/bunny-town-screenshot2.jpg"},
            {"src": "/images/portfolio/bunny-town-screenshot3.jpg"},
            {"src": "/images/portfolio/bunny-town-screenshot4.jpg"},
            {"src": "/images/portfolio/bunny-town-screenshot5.jpg"}],
    "links": [{"url": "https://mrhankey91.itch.io/bunny-town", "name": "Itchio"}],
};
const coinPusherData = {
    "title": "Coin Pusher",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unity3D",
    "description": "Coin pusher, where you need to collect food to feed your fighters and farmers. Every round you have to feed your fighters and farmers. If you don't feed them they will be dropped back.",
    "mywork": "I made the 3D models in Autodesk Maya, textures in Photoshop and programmed the game using c#.",
    "videos": [{"src": "https://www.youtube.com/embed/g0UknlGlJ_A?si=UG7WqCgogn2DfphO", "width": "560px", "height": "315px"}],
    "imagecolumns": 1,
    "images": [{"src": "/images/portfolio/coinpusher_screenshot.png"}],
    "links": [{"url": "https://mrhankey91.itch.io/coin-pusher", "name": "Itchio"}],
};
const escapeRoomData = {
    "title": "Escape Room",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unreal Engine",
    "description": "Escape Room is a puzzle game made with Unreal Engine.\n\n"+
            "The player must find the key to open the door and escape the room. To find this key, he/she has to complete various puzzles.",
    "mywork": "I made the 3D models in Autodesk Maya, textures in Photoshop and programmed the game using Blueprints.",
    "videos": [{"src": "https://www.youtube.com/embed/mkN7P7s8g7c", "width": "560px", "height": "315px"}],
    "imagecolumns": 1,
    "images": [{"src": "/images/portfolio/EscapeRoom_1.jpg"},
            {"src": "/images/portfolio/EscapeRoom_2.jpg"},
            {"src": "/images/portfolio/EscapeRoom_3.jpg"}],
    "links": [],
};
const artData = {
    "title": "2D & 3D Art",
    "subtitle": "Illustrator / Photoshop",
    "subsubtitle": "",
    "description": "A selection of my art.",
    "mywork": "I created the art in Adobe photoshop and Illustrator. The 3D models are made with Autodesk Maya",
    "videos": [],
    "imagecolumns": 3,
    "images": [{"src": "/images/portfolio/PixelArt.png"},
                {"src": "/images/portfolio/HouseModel.jpg"},
                {"src": "/images/portfolio/PrototypeArt_Cards.png"},
                {"src": "/images/portfolio/PrototypeArt_Items.png"},
                {"src": "/images/portfolio/People.png"},
                {"src": "/images/portfolio/Animation.gif"},
                {"src": "/images/portfolio/BearGirl.jpg"}],
    "links": [],
};
const truckTruckData = {
    "title": "TruckTruck",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unity3D",
    "description": "TruckTruck is an Android game where the player drives a delivery truck. The player receives coins every time he/she makes a delivery.\n\n"+
            "The goal is to reach as far as possible.",
    "mywork": "I made the 3D models in Autodesk Maya. Textures are made with Adobe Photoshop and the user interface with Adobe Illustrator.\n\n"+
            "The used game engine is Unity3D and I programmed the game with c#.",
    "videos": [{"src": "https://www.youtube.com/embed/q_x-LG5LLRQ", "width": "300px", "height": "480px"}],
    "imagecolumns": 3,
    "images": [{"src": "/images/portfolio/TruckTruck_Menu.jpg"},
            {"src": "/images/portfolio/TruckTruck_Game1.jpg"},
            {"src": "/images/portfolio/TruckTruck_Game2.jpg"}],
    "links": [],
};
const volcanoData = {
    "title": "Volcano",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "GameMaker",
    "description": "In Volcano you fall into the volcano. Win ores and fighting enemies. Reach the portal and upgrade your character. Continue the fall.",
    "mywork": "I made the graphics with Adobe Photoshop and programmed the game in GML (GameMaker Language).",
    "videos": [{"src": "https://www.youtube.com/embed/eXgpnx4jLPI", "width": "300px", "height": "480px"}],
    "imagecolumns": 2,
    "images": [{"src": "/images/portfolio/Volcano_Game1.jpg"},
            {"src": "/images/portfolio/Volcano_Game2.jpg"},
            {"src": "/images/portfolio/Volcano_Game3.jpg"},
            {"src": "/images/portfolio/Volcano_Game4.jpg"}],
    "links": [],
};
const kingdomLandsData = {
    "title": "Kingdom Lands",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unity3D",
    "description": "Rule and develop your kingdom and protect it from invaders. Manage your resources and build buildings.",
    "mywork": "I made the graphics with Adobe Photoshop and programmed the game in c#.",
    "videos": [],
    "imagecolumns": 2,
    "images": [{"src": "/images/portfolio/KingdomLands_Game1.jpg"},
            {"src": "/images/portfolio/KingdomLands_Game2.jpg"},
            {"src": "/images/portfolio/KingdomLands_Game3.jpg"},
            {"src": "/images/portfolio/KingdomLands_Game4.jpg"}],
    "links": [],
};
const hungryCatsData = {
    "title": "Hungry Cats 3D",
    "subtitle": "Game Design / Programming / Art",
    "subsubtitle": "Unity3D",
    "description": "Hungry Cats is an Android game where the player is a hungry cat. The player scores a point for every food item he ate. "+
            "But there are dangerous items which he shouldn't eat.\n\n"+
            "Eat as much as you can!",
    "mywork": "I made the 3D models in Autodesk Maya. Textures are made with Adobe Photoshop and the user interface with Adobe Illustrator.\n\n"+
            "The used game engine is Unity3D and I programmed the game with c#.",
    "videos": [{"src": "https://www.youtube.com/embed/LBH1J6yiQno", "width": "540px", "height": "360px"}],
    "imagecolumns": 3,
    "images": [{"src": "/images/portfolio/HungryCats_ScreenShots_MainMenu.jpg"},
            {"src": "/images/portfolio/HungryCats_ScreenShots_Game1.jpg"},
            {"src": "/images/portfolio/HungryCats_ScreenShots_Game2.jpg"},
            {"src": "/images/portfolio/HungryCats_ScreenShots_Game3.jpg"},
            {"src": "/images/portfolio/HungryCats_ScreenShots_Game4.jpg"}],
    "links": [{"url": "https://play.google.com/store/apps/details?id=com.XtaseStudios.HungryCats", "name": "Google Play"}],
};
const animationsData = {
    "title": "3D Animations",
    "subtitle": "3D Modeling / Animations",
    "subsubtitle": "Maya / Unity3D",
    "description": "Some animations made with Maya and Unity3D. ",
    "mywork": "I made the 3D models in Autodesk Maya and the animations in Unity3D",
    "videos": [{"src": "/videos/PipeItems.mp4", "width": "270px", "height": "480px"},
        {"src": "/videos/Cannon.mp4", "width": "270px", "height": "480px"},
        {"src": "/videos/SpringCube.mp4", "width": "270px", "height": "480px"}],
    "imagecolumns": 3,
    "images": [],
    "links": [{"url": "https://www.tiktok.com/@mrhankey91", "name": "TikTok"}],
};
const errorData = {
    "title": "Item Not Found",
    "subtitle": "Wrong Destination",
    "subsubtitle": "",
    "description": "",
    "mywork": "",
    "video": "",
    "imagecolumns": 3,
    "images": [],
    "links": [],
}

export const getData = (product) => {
    return(
        (product === "butsbal" && butsbalData) ||
        (product === "dungeonsweeper" && dungeonSweeperData) ||
        (product === "lightroom" && lightroomData) ||
        (product === "escaperoom" && escapeRoomData) ||
        (product === "art" && artData) ||
        (product === "trucktruck" && truckTruckData) ||
        (product === "volcano" && volcanoData) ||
        (product === "kingdomlands" && kingdomLandsData) ||
        (product === "hungrycats" && hungryCatsData) ||
        (product === "moneygarden" && moneyGardenData) ||
        (product === "3danimations" && animationsData) ||
        (product === "farmerij" && farmerijData) ||
        (product === "bunnytown" && bunnyTownData) ||
        (product === "coinpusher" && coinPusherData) ||
        errorData
    )
};