import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import PortfolioItems from './components/PortfolioItems';
import Contact from './components/Contact';
import Resume from './components/Resume';
import ProductPage from './components/ProductPage';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<PortfolioItems />}></Route>
        <Route path="/resume" element={<Resume />}></Route>
        <Route path = "/portfolio/:page" element={<ProductPage />}></Route>
        <Route path="/:filter" element={<PortfolioItems />}></Route>
      </Routes>
      <Contact />
    </Router>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
