import React from 'react';

export const SVGTwitter = () =>{
    return(
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="40px" height="40px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
        <path className="icon" fill="#FFFFFF" d="M20,0C8.954,0,0,8.954,0,20s8.954,20,20,20s20-8.954,20-20S31.046,0,20,0z M28.307,20.401
            c-1.779,7.21-13.722,10.268-19.217,2.783c2.106,2.008,5.77,2.184,8.094-0.217c-1.363,0.2-2.355-1.137-0.681-1.859
            c-1.506,0.166-2.342-0.635-2.686-1.314c0.352-0.37,0.742-0.541,1.496-0.591c-1.649-0.39-2.257-1.195-2.444-2.174
            c0.458-0.109,1.03-0.202,1.343-0.16c-1.444-0.755-1.945-1.89-1.867-2.743c2.581,0.957,4.224,1.727,5.599,2.464
            c0.49,0.261,1.036,0.731,1.652,1.329c0.784-2.075,1.755-4.212,3.415-5.273c-0.026,0.241-0.155,0.464-0.327,0.648
            c0.474-0.429,1.084-0.723,1.705-0.809c-0.07,0.467-0.742,0.731-1.148,0.883c0.307-0.096,1.941-0.824,2.119-0.409
            c0.211,0.471-1.127,0.689-1.354,0.771c-0.17,0.057-0.34,0.12-0.507,0.188c2.07-0.207,4.048,1.502,4.625,3.622
            c0.041,0.153,0.082,0.321,0.12,0.499c0.757,0.282,2.13-0.014,2.571-0.285c-0.32,0.757-1.15,1.312-2.377,1.415
            c0.592,0.245,1.705,0.38,2.473,0.249C30.423,19.938,29.639,20.411,28.307,20.401z"/>
        </svg>
    );
}