import React, {useEffect} from 'react';
import '../styles/ProductPage.scss';
import OnScreen from './etc/OnScreen';
import {getData} from '../data/ProductData';
import {useLocation} from 'react-router-dom';


const ProductPage = () =>{

    const params = useLocation();
    const page = params.pathname.split("/");
    const data = getData(page[2]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    /*const videoStyle = {
        maxWidth: data.video.width,
        maxHeight: data.video.height
      };*/
      
    return(
        <div>
            <div className="page product">
                <section className="grid">
                <OnScreen anim="slidefade-in" options={{rootMargin: "-100px"}}>
                    <h1>{data.title}</h1>
                    <h2>{data.subtitle}</h2>
                    <h3>{data.subsubtitle}</h3>
                </OnScreen>
                <OnScreen anim="fade-in" options={{rootMargin: "-100px"}}>
                    <hr/>
                </OnScreen>
        {
            data.description !== "" &&
            <>
                <OnScreen anim="slidefade-in" options={{rootMargin: "-100px"}}>
                    <h1 className="description-title">Description</h1>
                    <p className="description">{data.description}</p>
                </OnScreen>
                <OnScreen anim="fade-in" options={{rootMargin: "-100px"}}>
                    <hr/>
                </OnScreen>
            </>
        }
        {
            data.mywork !== "" &&
            <>
                <OnScreen anim="slidefade-in" options={{rootMargin: "-100px"}}>
                    <h1 className="work-title">My work</h1>
                    <p className="work">{data.mywork}</p>
                </OnScreen>
                <OnScreen anim="fade-in" options={{rootMargin: "-100px"}}>
                    <hr/>
                </OnScreen>
            </>
        }
        {
            data.links.length !== 0 &&
            <>
                <div className="links">
                    <h1 className="work-title">Links</h1>
                    {
                        data.links.map((link) =>
                            <OnScreen anim="slidefade-in" options={{rootMargin: "-100px"}}>
                                <a href={link.url} alt={link.name} target="_blank">{link.name}</a>
                            </OnScreen>
                        )
                    }
                </div>
                <OnScreen anim="fade-in" options={{rootMargin: "-100px"}}>
                    <hr/>
                </OnScreen>
            </>
        }
        {
            data.videos.length !== 0 &&
            <>
                <div className={"videos"}>
                    {
                        data.videos.map((video) =>
                            <OnScreen anim="slidefade-in" options={{rootMargin: "-100px"}}>
                                <iframe className="video" style={{maxWidth: video.width,maxHeight: video.height}} title="video" src={video.src} width={video.width} height={video.height} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; loop" loop allowFullScreen></iframe>
                            </OnScreen>
                        )
                    }
                    <OnScreen anim="fade-in" options={{rootMargin: "-100px"}}>
                        <hr/>
                    </OnScreen>
                </div>
            </>
        }
        
                    <div className={"images-"+data.imagecolumns}>
                        {
                            data.images.map((image) =>
                                <OnScreen anim="slidefade-in" options={{rootMargin: "-100px"}}>
                                    <img src={image.src} alt=""/>
                                </OnScreen>
                            )
                        }
                    </div>

                </section>
            </div>
        </div>);
}

export default ProductPage;