import React, {} from 'react';
import {Link} from 'react-router-dom';
import PortfolioItem from './PortfolioItem';
import '../styles/Portfolio.scss';
import {portfolioData} from '../data/PortfolioData.js';
import {useLocation} from 'react-router-dom';

const PortfolioItems = () =>{

const params = useLocation();
const pathname = params.pathname.split('/')[1];

    return(<div className="page">
        <h1 className="text-center text-size-40">Robert Vriens's Portfolio</h1>
        <h2 className="text-center text-size-20">A selection of featured work.</h2>
        <div className="text-center text-size-15 p0">
            <Link className={"portfolio-filter" + (params.pathname === undefined || params.pathname === "/" ? " active" : "")} to="../">All</Link>
            <Link className={"portfolio-filter" + (params.pathname === "/game" ? " active" : "")} to="../game">Games</Link>
            <Link className={"portfolio-filter" + (params.pathname === "/web" ? " active" : "")} to="../web">Websites</Link>
            <Link className={"portfolio-filter" + (params.pathname === "/design" ? " active" : "")} to="../design">Design</Link>
            <Link className={"portfolio-filter" + (params.pathname === "/others" ? " active" : "")} to="../others">Others</Link>
        </div>

        <section className="portfolio-grid-container">
            <section className="portfolio-grid col-start-2">
                {
                    portfolioData.items.map((item, index) =>{
                            if(params.pathname === undefined || params.pathname === "/" || item.type === pathname)
                                return (<PortfolioItem key={index} item={item} />);
                            else
                                return (<></>);
                        }
                    )
                }
            </section>
        </section>
    </div>);
}

export default PortfolioItems;