import React from 'react';
import '../styles/Contact.scss';
import {SVGFacebook as FacebookIcon} from '../svg/facebook.js';
import {SVGLinkedIn as LinkedInIcon} from '../svg/linkedin.js';
import {SVGTwitter as TwitterIcon} from '../svg/twitter.js';
import {SVGTikTok as TikTokIcon} from '../svg/tiktok.js';

const Contact = () =>{
    return(<div>
        <div className="blank-page"></div>
        <div className="contact-page">
        <div className="full-width absolute vertical-middle text-center">
            <img className="logo" src="../images/logo.svg" alt="Logo" />
            <h1 className="mt-20 text-size-20">Robert Vriens</h1>
            <p className="m0 text-size-15">Game Developer / Web Designer</p>
            <p className="m0 mb-20 text-size-15">
                robert<span className="none">fail</span>@vriens.me
            </p>
            <div className="socialmedia">
                <a className="socialmedia-icon facebook" href="https://www.facebook.com/mrhankey91" target="_blank" rel="noopener noreferrer">
                    <FacebookIcon />
                </a>
                <a className="socialmedia-icon linkedin" href="https://www.linkedin.com/profile/view?id=AAMAABSHgTsBegkmuzrECH_7h9wWqXxBJAyblBU&trk=hp-identity-name" target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon />
                </a>
                <a className="socialmedia-icon twitter" href="https://twitter.com/Mrhankey91" target="_blank" rel="noopener noreferrer">
                    <TwitterIcon />
                </a>
            </div>
            </div>
        </div>
    </div>);
}

export default Contact;