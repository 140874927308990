import React from 'react';

export const SVGTikTok = () =>{
    return(
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="40px" height="40px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
        <path className="icon" fill="#FFFFFF" d="M20,0C9,0,0,9,0,20c0,11,9,20,20,20s20-9,20-20C40,9,31,0,20,0z M28,18.2L28,18.2c-0.2,0-0.3,0-0.5,0
	c-1.7,0-3.2-0.8-4.2-2.2v7.7c0,3.1-2.5,5.7-5.7,5.7c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7c0.1,0,0.2,0,0.3,0v2.8
	c-0.1,0-0.2,0-0.3,0c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,3-1.3,3-2.9l0-13h2.7c0.3,2.4,2.2,4.3,4.6,4.4V18.2z"/>
        </svg>
    );
}