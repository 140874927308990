import React from 'react';

export const SVGFacebook = (props) => {
    return (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="40px" height="40px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
<path className="icon" fill="#FFFFFF" d="M20,0C8.954,0,0,8.954,0,20s8.954,20,20,20s20-8.954,20-20S31.046,0,20,0z M23.508,15.119
    c-0.654,0-1.361,0-1.697,0s-0.676,0.348-0.676,0.607c0,0.257,0,1.73,0,1.73s2.098,0,2.369,0c-0.094,1.326-0.291,2.539-0.291,2.539
    h-2.09v7.521h-3.114v-7.521h-1.517v-2.53h1.517c0,0,0-1.69,0-2.069s-0.077-2.911,3.192-2.911c0.438,0,1.432,0,2.307,0
    C23.508,13.585,23.508,14.775,23.508,15.119z"/>
</svg>);
}