export const resumeData = {
    "educations": [
        { "years": "2011 - 2015", "name": "Informatica, Game Design", "school": "Amsterdam University of Applied Scineces, Amsterdam" },
        { "years": "2013 - 2014", "name": "Minor, Learning Experience Design", "school": "Avans, Breda" },
        { "years": "2008 - 2010", "name": "Informatica, Software Engineering", "school": "Fontys, Eindhoven" },
        { "years": "2003 - 2008", "name": "Highschool, Havo", "school": "Dr.-Knippenbergcollege, Helmond" },
    ],
    "skills": [
        {"design": ["After Effects", "Illustrator", "InDesign", "Lightroom", "Maya", "Photoshop", "Premiere", "CSS / SCSS", "SketchUp"]},
        {"technical": ["C#", "GameMaker", "HTML", "Javascript", "PHP", "React", "Unity3D", "Unreal Engine"]}
    ],
    "languages": [{"language": "Dutch", "level": "Native"}, {"language": "English", "level": "Decent"}]
}