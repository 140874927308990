import React, {} from 'react';
import {Link} from 'react-router-dom';
import OnScreen from './etc/OnScreen';

const PortfolioItem = ({props, item}) =>{
    const randomColor = () =>{
        const min = 1;
        const max = 3;
        var rand =  Math.round(min + (Math.random() * (max-min)));
        return (" c" + rand);
    }

    const scrollUp = () =>{
        window.scrollTo(0, 0);
    }
    scrollUp();

    const CreateLink = ({children}) =>{
        //var r = (item.link === "/" ? (<Link className={"portfolio-item"+(randomColor())} to={item.link}>{children}</Link>) : (<a className={"portfolio-item"+(randomColor())} href={item.link}>{children}</a>));
        
        return(
            item.link.charAt(0) === "/" ? 
                (<Link className={"portfolio-item"+(randomColor())} to={item.link}>{children}</Link>) : 
                (<a className={"portfolio-item"+(randomColor())} href={item.link} target="_blank" rel="noopener noreferrer">{children}</a>)
        );
    }

    return(
        <OnScreen anim="slidefade-in" options={ {rootMargin: "-100px"} }>
        <CreateLink >
            <img className="image" src={item.image} alt=""></img>
            <div className="info">
                <h1 className="title">{item.title}</h1>
                <p className="description">{item.description}</p>
                <p className="extra">{item.extratext}</p>
            </div>
        </CreateLink>
        </OnScreen>
    )
}

export default PortfolioItem;