export const portfolioData = {
    "items": [
        {
            "title": "Butsbal", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "Steam",
            "image": "../../../images/portfolio/butsbal_thumb.png",
            "link": "/portfolio/butsbal"
        },
        {
            "title": "Money Garden", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "Steam",
            "image": "../../../images/portfolio/MoneyGarden.png",
            "link": "/portfolio/moneygarden"
        },
        {
            "title": "3D Animations", 
            "type": "design",
            "description": "3D Modeling / Animations",
            "extratext": "",
            "image": "../../../images/portfolio/3danimations_thumb.jpg",
            "link": "/portfolio/3danimations"
        },
        {
            "title": "Farmerij", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "Itchio",
            "image": "../../../images/portfolio/farmerij-thumb.png",
            "link": "/portfolio/farmerij"
        },
        {
            "title": "Bunny Town", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "Itchio",
            "image": "../../../images/portfolio/bunnytown-thumb.gif",
            "link": "/portfolio/bunnytown"
        },
        {
            "title": "Coin Pusher", 
            "type": "game",
            "description": "Game Design / 3D Modeling / Programming",
            "extratext": "Itchio",
            "image": "../../../images/portfolio/coinpusher-thumb.png",
            "link": "/portfolio/coinpusher"
        },
        {
            "title": "Photo Editing", 
            "type": "design",
            "description": "Lightroom",
            "extratext": "",
            "image": "../../../images/portfolio/Lightroom_4.jpg",
            "link": "/portfolio/lightroom"
        },
        {
            "title": "Robert Vriens", 
            "type": "others",
            "description": "Designer / Developer",
            "extratext": "resumé",
            "image": "../../../images/portfolio/rv.jpg",
            "link": "/resume"
        },
        {
            "title": "Google Released Games", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "",
            "image": "../../../images/portfolio/HoldYourFinger.jpg",
            "link": "https://play.google.com/store/apps/dev?id=9040426684837425932"
        },
        {
            "title": "C# Samples", 
            "type": "others",
            "description": "c# / Unity3D",
            "extratext": "",
            "image": "../../../images/portfolio/Csharp_thumb.jpg",
            "link": "https://github.com/Mrhankey91/Portfolio"
        },
        {
            "title": "Photo Portfolio", 
            "type": "web",
            "description": "Web Design / React",
            "extratext": "",
            "image": "../../../images/portfolio/Iceland.jpg",
            "link": "https://photos.vriens.me"
        },
        {
            "title": "React Demos", 
            "type": "web",
            "description": "Web Design / React",
            "extratext": "",
            "image": "../../../images/portfolio/React-Demos.png",
            "link": "https://react.vriens.me"
        },
        {
            "title": "Hungry Cats 3D", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "Android / Unity3D",
            "image": "../../../images/portfolio/HungryCats_GameIcon_512x512.png",
            "link": "/portfolio/hungrycats"
        },
        {
            "title": "Escape Room", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "",
            "image": "../../../images/portfolio/EscapeRoom_2.jpg",
            "link": "/portfolio/escaperoom"
        },
        {
            "title": "Art", 
            "type": "design",
            "description": "Illustrator / Photoshop",
            "extratext": "",
            "image": "../../../images/portfolio/HouseModel.jpg",
            "link": "/portfolio/art"
        },
        {
            "title": "Kingdom Lands", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "",
            "image": "../../../images/portfolio/KingdomLands_thumb.jpg",
            "link": "/portfolio/kingdomlands"
        },
        {
            "title": "Dungeon Sweeper", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "",
            "image": "../../../images/portfolio/DungeonSweeper_thumb.jpg",
            "link": "/portfolio/dungeonsweeper"
        },
        {
            "title": "Volcano", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "",
            "image": "../../../images/portfolio/Volcano_thumb.jpg",
            "link": "/portfolio/volcano"
        },
        {
            "title": "TruckTruck", 
            "type": "game",
            "description": "Game Design / Programming / Art",
            "extratext": "",
            "image": "../../../images/portfolio/TruckTruck_thumb.jpg",
            "link": "/portfolio/trucktruck"
        }
    ]
};