import React from 'react';
import '../styles/Portfolio.scss';
import '../styles/Resume.scss';
import {resumeData} from '../data/ResumeData.js';
import OnScreen from './etc/OnScreen';

const Resume = () =>{
    return(
        <div>
            <div className="page resume">

                 <OnScreen anim="slidefade-in" options={ {rootMargin: "-100px"} }>
                    <h1 className="text-center">Demo Reel</h1>
                    <iframe style={{maxWidth: "560px", maxHeight: "315px"}} title="showreel" className="video block ma" width="560" height="315" src="https://www.youtube.com/embed/5oLMqLeTQRw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </OnScreen>
                <hr className="ma-50"/>
                <OnScreen anim="slidefade-in" options={ {rootMargin: "-100px"} }>
                    <h1 className="text-center text-size-30 mb-0">Resumé</h1>
                    <h2 className="text-center text-size-15 m0 mb-20">Robert Vriens</h2>
                </OnScreen>
                <hr className="ma-50"/>
                <section className="grid">
                    <OnScreen anim="slidefade-in" options={ {rootMargin: "-100px"} }>
                        <h1 className="text-size-20 text-center m0 mb-20">Education</h1>
                        <section className="resume-grid schools">
                            {
                                resumeData.educations.map((education) => <>
                                    <p className="col-start-1 m0 mt-20 text-weight-b">{education.years}</p>
                                    <p className="col-start-2 m0 mt-20">{education.name}</p>
                                    <p className="col-start-2 m0">{education.school}</p></>
                                )
                            }
                        </section>
                    </OnScreen>
                    <hr className="ma-40"/>
                    <OnScreen anim="slidefade-in" options={ {rootMargin: "-100px"} }>
                        <h1 className="text-size-20 text-center m0 mb-20">Skills</h1>
                        <section className="resume-grid skills">
                            <h2 className="row-start-1 col-start-1 m0 mt-20 text-size-15 text-weight-b">Design</h2>
                            <h2 className="row-start-1 col-start-2 m0 mt-20 text-size-15 text-weight-b">Technical</h2>
                            {
                                resumeData.skills[0].design.map((design, index) => 
                                    <p className={"row-start-"+(index+2)+" col-start-1 m0"}>{design}</p>
                                )
                            }
                            {
                                resumeData.skills[1].technical.map((technical, index) => 
                                    <p className={"row-start-"+(index+2)+" col-start-2 m0"}>{technical}</p>
                                )
                            }
                        </section>
                    </OnScreen>
                    <hr className="ma-40"/>
                    <OnScreen anim="slidefade-in" options={ {rootMargin: "-100px"} }>
                        <h1 className="text-size-20 text-center m0 mb-20">Languages</h1>
                        <section className="resume-grid languages text-center">
                            {
                                resumeData.languages.map((language, index) => <>
                                    <p className="col-start-2 m0">{language.language}</p>
                                    <p className="col-start-3 m0">{language.level}</p>
                                    </>
                                )
                            }
                        </section>
                    </OnScreen>
                </section>
                <OnScreen anim="slidefade-in" options={ {rootMargin: "-100px"} }>
                <div className="download" >
                    <a href="../data/Resume-RobertVriens.pdf"target="_self">Download Resume</a>
                </div>
                </OnScreen>
            </div>
            <div className="blank-page"></div>
        </div>
    );
}
export default Resume;