// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
  max-width: 640px;
  margin: auto;
}

.resume-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  z-index: 1;
}

.resume-grid.schools, .resume-grid.skills {
  grid-template-columns: 160px auto;
  grid-row-gap: 0px;
  grid-column-gap: 20px;
}

.resume-grid.languages {
  grid-template-columns: auto 80px 80px auto;
  grid-row-gap: 0px;
  grid-column-gap: 20px;
}

.resume-grid p {
  font-size: 1rem;
}

.resume hr {
  border: 1px solid;
  border-color: #fff;
}

.resume h1 {
  color: #f07c81;
}`, "",{"version":3,"sources":["webpack://./src/styles/Resume.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,YAAA;AADJ;;AAGA;EACI,aAAA;EACA,gCAAA;EACA,kBAAA;EACA,qBAAA;EACA,UAAA;AAAJ;;AAEA;EACI,iCAAA;EACA,iBAAA;EACA,qBAAA;AACJ;;AACA;EACI,0CAAA;EACA,iBAAA;EACA,qBAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AADA;EACI,iBAAA;EACA,kBCxBW;AD4Bf;;AAFA;EACI,cCvBW;AD4Bf","sourcesContent":["@import './variables.scss';\r\n\r\n.grid{\r\n    max-width: 640px;\r\n    margin: auto;\r\n}\r\n.resume-grid{\r\n    display: grid;\r\n    grid-template-columns: auto auto;\r\n    grid-row-gap: 20px;\r\n    grid-column-gap: 20px;\r\n    z-index: 1;\r\n}\r\n.resume-grid.schools, .resume-grid.skills{\r\n    grid-template-columns: 160px auto;\r\n    grid-row-gap: 0px;\r\n    grid-column-gap: 20px;\r\n}\r\n.resume-grid.languages{\r\n    grid-template-columns: auto 80px 80px auto;\r\n    grid-row-gap: 0px;\r\n    grid-column-gap: 20px;\r\n}\r\n.resume-grid p{\r\n    font-size: 1.0rem;\r\n}\r\n.resume hr{\r\n    border: 1px solid;\r\n    border-color: $text-color-1;\r\n}\r\n.resume h1{\r\n    color: $text-color-5;\r\n}","$color-1: #fff;\r\n$color-2: #282828;\r\n$color-3: #e1e1e1;\r\n\r\n$text-color-1: #fff;\r\n$text-color-2: #282828;\r\n$text-color-3: #e1e1e1;\r\n$text-color-4: #fec76a;\r\n$text-color-5: #f07c81;\r\n\r\n$background-color-1: #fff;\r\n$background-color-2: #282828;\r\n$background-color-3: #e1e1e1;\r\n$background-color-4: #fec76a;\r\n$background-color-5: #f07c81;\r\n\r\n$button-1: rgb(245, 32, 32);\r\n$button-1-h: rgb(221, 20, 20);\r\n$button-2: rgb(255, 185, 35);\r\n$button-2-h: rgb(231, 166, 25);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
